<template>
  <ion-grid
    v-if="!isMobile"
    ref="browseCategoryDesktop"
    class="browse-category-desktop custom-padding-home-page"
  >
    <ion-row>
      <ion-col
        size="12"
        class="ion-justify-content-center d-flex"
        style="margin-bottom: 45px"
      >
        <h2
          class="title"
          @click="focusCategories('browseCategoryDesktop')"
        >
          Browse below by Category
        </h2>
        <div
          class="browse-cat-cta d-flex ion-justify-content-center"
          @click="focusCategories('browseCategoryDesktop')"
        >
          <img
            loading="lazy"
            src="@/assets/icons/arrowRightPlain.svg"
            alt="Browse Below By Category"
            width="50"
            height="20"
          >
        </div>
      </ion-col>
    </ion-row>
    <ion-row class="item-box content-container">
      <ion-col
        id="searchbycat_type_general_trailer"
        class="item"
        :class="activeCategory === 'General Trailers' ? 'active' : ''"
        @click="selectCategory('General Trailers')"
      >
        <ion-row>
          <ion-col
            size="12"
            class="ion-text-center"
          >
            <img
              loading="lazy"
              width="81.25"
              height="50"
              class="trailer-img"
              alt="trailerImg"
              src="@/assets/equipmentTrailer.webp"
            >
          </ion-col>
          <ion-col
            size="12"
            class="ion-text-center"
          >
            <ion-text class="category-title">
              General Trailers
            </ion-text>
          </ion-col>
        </ion-row>
      </ion-col>
      <ion-col
        id="searchbycat_type_horse_trailer"
        :class="activeCategory === 'Horse & Livestock' ? 'active' : ''"
        class="item"
        @click="selectCategory('Horse & Livestock')"
      >
        <ion-row>
          <ion-col
            size="12"
            class="ion-text-center"
          >
            <img
              loading="lazy"
              width="73.52"
              height="50"
              class="trailer-img"
              alt="trailerImg"
              src="@/assets/horseLivestock.webp"
            >
          </ion-col>
          <ion-col
            size="12"
            class="ion-text-center"
          >
            <ion-text class="category-title">
              Horse and LiveStock
            </ion-text>
          </ion-col>
        </ion-row>
      </ion-col>
      <ion-col
        id="searchbycat_type_travel"
        :class="activeCategory === 'Travel Trailers' ? 'active' : ''"
        class="item"
        @click="selectCategory('Travel Trailers')"
      >
        <ion-row>
          <ion-col
            size="12"
            class="ion-text-center"
          >
            <img
              loading="lazy"
              width="89.78"
              height="50"
              class="trailer-img"
              alt="trailerImg"
              src="@/assets/travelTrailers.webp"
            >
          </ion-col>
          <ion-col
            size="12"
            class="ion-text-center"
          >
            <ion-text class="category-title">
              Travel Trailers
            </ion-text>
          </ion-col>
        </ion-row>
      </ion-col>
      <ion-col
        id="searchbycat_type_semi"
        :class="activeCategory === 'Semi Trailers' ? 'active' : ''"
        class="item"
        @click="selectCategory('Semi Trailers')"
      >
        <ion-row>
          <ion-col
            size="12"
            class="ion-text-center"
          >
            <img
              loading="lazy"
              width="100"
              height="50"
              class="trailer-img"
              alt="trailerImg"
              src="@/assets/semiTrailers.webp"
            >
          </ion-col>
          <ion-col
            size="12"
            class="ion-text-center"
          >
            <ion-text class="category-title">
              Semi Trailers
            </ion-text>
          </ion-col>
        </ion-row>
      </ion-col>
      <ion-col
        id="searchbycat_type_truckbeds"
        :class="activeCategory === 'Truck Beds' ? 'active' : ''"
        class="item"
        @click="selectCategory('Truck Beds')"
      >
        <ion-row>
          <ion-col
            size="12"
            class="ion-text-center"
          >
            <img
              loading="lazy"
              width="138.75"
              height="50"
              class="trailer-img"
              alt="trailerImg"
              src="@/assets/truckBeds.svg"
            >
          </ion-col>
          <ion-col
            size="12"
            class="ion-text-center"
          >
            <ion-text class="category-title">
              Truck Beds
            </ion-text>
          </ion-col>
        </ion-row>
      </ion-col>
    </ion-row>
    <ion-row
      v-if="isDesktop"
      class="content-container"
    >
      <ion-col
        v-for="item in typeList"
        :key="item.id"
        size="6"
      >
        <ion-row class="sub-box">
          <ion-col
            size="auto"
            class="sub-img"
          >
            <ion-img
              :src="item.image.image_url"
              class="menu-image"
              :alt="item.name"
              @ionError="onImageLoadError"
            />
            <div :class="menuList.length === 0 ? 'custom-skeleton-loader' : ''" />
          </ion-col>
          <ion-col style="padding-top: 30px; width: 1%">
            <ion-row>
              <ion-col size="12">
                <ion-text class="sub-title">
                  {{ item.name }}
                </ion-text>
              </ion-col>
              <ion-col size="12">
                <ion-text class="sub-description">
                  {{ item.description }}
                </ion-text>
              </ion-col>
              <ion-col size="12">
                <ion-button
                  :id="generatedIdByTypeCategory(item)"
                  :href="selectedCategoryHref(item.name)"
                  class="btn btn-submit"
                  @click="goToSelectedCategory(item.name, true, $event)"
                >
                  Shop {{ item.name }}
                  <ion-icon :icon="chevronForwardOutline" />
                </ion-button>
              </ion-col>
            </ion-row>
          </ion-col>
        </ion-row>
      </ion-col>
    </ion-row>
    <ion-row
      v-else
      class="ion-hide-xl-up"
    >
      <ion-col
        v-for="item in typeList"
        :key="item.id"
        size="6"
      >
        <ion-row class="sub-box">
          <ion-col
            size="12"
            class="sub-img"
          >
            <ion-img
              :src="item.image.image_url"
              class="menu-image"
              :alt="item.name"
              @ionError="onImageLoadError"
            />
          </ion-col>
          <ion-col
            size="12"
            style=""
          >
            <ion-row>
              <ion-col size="12">
                <ion-text class="sub-title">
                  {{ item.name }}
                </ion-text>
              </ion-col>
              <ion-col size="12">
                <div class="max-text">
                  <ion-text class="sub-description-tablet">
                    {{ item.description }}
                  </ion-text>
                </div>
              </ion-col>
              <ion-col size="12">
                <ion-button
                  :id="generatedIdByTypeCategory(item)"
                  :href="selectedCategoryHref(item.name)"
                  class="btn btn-submit"
                  @click="goToSelectedCategory(item.name, true, $event)"
                >
                  Shop {{ item.name }}
                  <ion-icon :icon="chevronForwardOutline" />
                </ion-button>
              </ion-col>
            </ion-row>
          </ion-col>
        </ion-row>
      </ion-col>
    </ion-row>
    <ion-row class="content-container-bottom-desktop">
      <ion-col>
        <div>
          <p class="content-text">
            Explore TrailerTrader’s curated selection of trailer categories, it is designed to simplify your search for trailers,
            horse and livestock trailers, travel trailers and RVs, semi-trailers and truckbeds. We've distilled the industry's 
            extensive category list into a user-friendly collection, ensuring you find the right trailer without the technical 
            jargon. Start by selecting a category on our homepage, then fine-tune your choice with detailed filters on the 
            listings page. Don’t forget to set your location so that you have all results near you.
          </p>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
  <ion-grid
    v-else
    ref="browseCategoryMobile"
    class="browse-category-mobile"
  >
    <ion-row>
      <ion-col
        size="12"
        style="margin-bottom: 20px; margin-top: 20px"
        class="ion-text-center ion-justify-content-center d-flex"
      >
        <h2
          class="title"
          @click="focusCategories('browseCategoryMobile')"
        >
          Browse below by Category
        </h2>
        <div
          class="browse-cat-cta d-flex ion-justify-content-center"
          @click="focusCategories('browseCategoryMobile')"
        >
          <img
            loading="lazy"
            src="@/assets/icons/arrowRightPlain.svg"
            alt="Browse Below By Category"
            width="50"
            height="20"
          >
        </div>
      </ion-col>
    </ion-row>
    <ion-row class="item-box">
      <ion-col>
        <ion-row id="type-1">
          <ion-col
            id="searchbycat_type_general_trailer"
            class="item"
            size="12"
            @click="
              selectCategoryMobile('General Trailers');
            "
          >
            <ion-row>
              <ion-col
                size="2"
                class="ion-text-center"
              >
                <img
                  loading="lazy"
                  width="60"
                  height="35"
                  class="category-img"
                  alt="trailerImg"
                  src="@/assets/equipmentTrailer.webp"
                >
              </ion-col>
              <ion-col
                size="9"
                class="ion-text-start title-box"
              >
                <ion-text class="category-title">
                  General Trailers
                </ion-text>
              </ion-col>
              <ion-col
                size="1"
                class="ion-text-center title-box"
              >
                <span
                  id="caret-1"
                  :class="activeCategory === 'General Trailers' ? 'rotate-caret' : 'ion-float-right'"
                >
                  <img
                    loading="lazy"
                    alt="caretDown"
                    src="@/assets/icons/arrowDownBlue.svg"
                    width="16"
                    height="16"
                  >
                </span>
              </ion-col>
            </ion-row>
          </ion-col>
          <ion-row
            v-if="activeCategory === 'General Trailers'"
            :class="activeCategory === 'General Trailers' ? 'category-show' : 'category-hide'"
          >
            <ion-col
              v-for="item in getTypesMobile('General Trailers')"
              :id="generatedIdByTypeCategory(item)"
              :key="item.id"
              size="12"
            >
              <ion-row class="sub-box">
                <ion-col
                  size="auto"
                  class="sub-img"
                >
                  <ion-img
                    :src="item.image.image_url"
                    class="menu-image-mobile"
                    :alt="item.name"
                    @ionError="onImageLoadError"
                  />
                </ion-col>
                <ion-col>
                  <a :href="selectedCategoryHref(item.name)">
                    <ion-row
                      class="sub-title-box"
                      @click="goToSelectedCategory(item.name, true, $event)"
                    >
                      <ion-col
                        class="ion-text-start"
                        size="auto"
                      >
                        <ion-text class="sub-title">{{ item.name }}</ion-text> </ion-col><ion-col
                        size="auto"
                        class="arrow-right"
                      >
                        <img
                          loading="lazy"
                          alt="caretRight"
                          src="@/assets/icons/arrowGrey.svg"
                          width="9"
                          height="12"
                        >
                      </ion-col>
                    </ion-row>
                  </a>
                </ion-col>
              </ion-row>
            </ion-col>
          </ion-row>
        </ion-row>
      </ion-col>
      <ion-col
        id="searchbycat_type_horse_trailer"
        class="item"
        size="12"
        @click="
          selectCategoryMobile('Horse & Livestock');
        "
      >
        <ion-row id="type-2">
          <ion-col>
            <ion-row>
              <ion-col
                size="2"
                class="ion-text-center"
              >
                <img
                  loading="lazy"
                  width="60"
                  height="35"
                  class="category-img"
                  alt="trailerImg"
                  src="@/assets/horseLivestock.webp"
                >
              </ion-col>
              <ion-col
                size="9"
                class="ion-text-start title-box"
              >
                <ion-text class="category-title">
                  Horse & Livestock
                </ion-text>
              </ion-col>
              <ion-col
                size="1"
                class="ion-text-center title-box"
              >
                <span
                  id="caret-2"
                  :class="activeCategory === 'Horse & Livestock' ? 'rotate-caret' : 'ion-float-right'"
                >
                  <img
                    loading="lazy"
                    alt="caretDown"
                    src="@/assets/icons/arrowDownBlue.svg"
                    width="16"
                    height="16"
                  >
                </span>
              </ion-col>
            </ion-row>
          </ion-col>
          <ion-row
            v-if="activeCategory === 'Horse & Livestock'"
            :class="activeCategory === 'Horse & Livestock' ? 'category-show' : 'category-hide'"
          >
            <ion-col
              v-for="item in getTypesMobile('Horse & Livestock')"
              :id="generatedIdByTypeCategory(item)"
              :key="item.id"
              size="12"
            >
              <ion-row class="sub-box">
                <ion-col
                  size="auto"
                  class="sub-img"
                >
                  <ion-img
                    :src="item.image.image_url"
                    class="menu-image-mobile"
                    :alt="item.name"
                    @ionError="onImageLoadError"
                  />
                </ion-col>
                <ion-col>
                  <a :href="selectedCategoryHref(item.name)">
                    <ion-row
                      class="sub-title-box"
                      @click="goToSelectedCategory(item.name, true, $event)"
                    >
                      <ion-col
                        class="ion-text-start"
                        size="auto"
                      >
                        <ion-text class="sub-title">{{ item.name }}</ion-text> </ion-col><ion-col
                        size="auto"
                        class="arrow-right"
                      >
                        <img
                          loading="lazy"
                          alt="caretRight"
                          src="@/assets/icons/arrowGrey.svg"
                          width="9"
                          height="12"
                        >
                      </ion-col>
                    </ion-row>
                  </a>
                </ion-col>
              </ion-row>
            </ion-col>
          </ion-row>
        </ion-row>
      </ion-col>
      <ion-col
        id="searchbycat_type_travel"
        class="item"
        size="12"
        @click="
          selectCategoryMobile('Travel Trailers');
        "
      >
        <ion-row id="type-3">
          <ion-col>
            <ion-row>
              <ion-col
                size="2"
                class="ion-text-center"
              >
                <img
                  loading="lazy"
                  width="60"
                  height="35"
                  class="category-img"
                  alt="trailerImg"
                  src="@/assets/travelTrailers.webp"
                >
              </ion-col>
              <ion-col
                size="9"
                class="ion-text-start title-box"
              >
                <ion-text class="category-title">
                  Travel Trailers
                </ion-text>
              </ion-col>
              <ion-col
                size="1"
                class="ion-text-center title-box"
              >
                <span
                  id="caret-3"
                  :class="activeCategory === 'Travel Trailers' ? 'rotate-caret' : 'ion-float-right'"
                >
                  <img
                    loading="lazy"
                    alt="caretDown"
                    src="@/assets/icons/arrowDownBlue.svg"
                    width="16"
                    height="16"
                  >
                </span>
              </ion-col>
            </ion-row>
          </ion-col>
          <ion-row
            v-if="activeCategory === 'Travel Trailers'"
            :class="activeCategory === 'Travel Trailers' ? 'category-show' : 'category-hide'"
          >
            <ion-col
              v-for="item in getTypesMobile('Travel Trailers')"
              :id="generatedIdByTypeCategory(item)"
              :key="item.id"
              size="12"
            >
              <ion-row class="sub-box">
                <ion-col
                  size="auto"
                  class="sub-img"
                >
                  <ion-img
                    :src="item.image.image_url"
                    class="menu-image-mobile"
                    :alt="item.name"
                    @ionError="onImageLoadError"
                  />
                </ion-col>
                <ion-col>
                  <a :href="selectedCategoryHref(item.name)">
                    <ion-row
                      class="sub-title-box"
                      @click="goToSelectedCategory(item.name, true, $event)"
                    >
                      <ion-col
                        class="ion-text-start"
                        size="auto"
                      >
                        <ion-text class="sub-title">{{ item.name }}</ion-text> </ion-col><ion-col
                        size="auto"
                        class="arrow-right"
                      >
                        <img
                          loading="lazy"
                          alt="caretRight"
                          src="@/assets/icons/arrowGrey.svg"
                          width="9"
                          height="12"
                        >
                      </ion-col>
                    </ion-row>
                  </a>
                </ion-col>
              </ion-row>
            </ion-col>
          </ion-row>
        </ion-row>
      </ion-col>
      <ion-col
        id="searchbycat_type_semi"
        class="item"
        size="12"
        @click="
          selectCategoryMobile('Semi Trailers');
        "
      >
        <ion-row id="type-4">
          <ion-col>
            <ion-row>
              <ion-col
                size="2"
                class="ion-text-center"
              >
                <img
                  loading="lazy"
                  width="60"
                  height="35"
                  class="category-img"
                  alt="trailerImg"
                  src="@/assets/semiTrailers.webp"
                >
              </ion-col>
              <ion-col
                size="9"
                class="ion-text-start title-box"
              >
                <ion-text class="category-title">
                  Semi Trailers
                </ion-text>
              </ion-col>
              <ion-col
                size="1"
                class="ion-text-center title-box"
              >
                <span
                  id="caret-4"
                  :class="activeCategory === 'Semi Trailers' ? 'rotate-caret' : 'ion-float-right'"
                >
                  <img
                    loading="lazy"
                    alt="caretDown"
                    src="@/assets/icons/arrowDownBlue.svg"
                    width="16"
                    height="16"
                  >
                </span>
              </ion-col>
            </ion-row>
          </ion-col>
          <ion-row
            v-if="activeCategory === 'Semi Trailers'"
            :class="activeCategory === 'Semi Trailers' ? 'category-show' : 'category-hide'"
          >
            <ion-col
              v-for="item in getTypesMobile('Semi Trailers')"
              :id="generatedIdByTypeCategory(item)"
              :key="item.id"
              size="12"
            >
              <ion-row class="sub-box">
                <ion-col
                  size="auto"
                  class="sub-img"
                >
                  <ion-img
                    :src="item.image.image_url"
                    class="menu-image-mobile"
                    :alt="item.name"
                    @ionError="onImageLoadError"
                  />
                </ion-col>
                <ion-col>
                  <a :href="selectedCategoryHref(item.name)">
                    <ion-row
                      class="sub-title-box"
                      @click="goToSelectedCategory(item.name, true, $event)"
                    >
                      <ion-col
                        class="ion-text-start"
                        size="auto"
                      >
                        <ion-text class="sub-title">{{ item.name }}</ion-text> </ion-col><ion-col
                        size="auto"
                        class="arrow-right"
                      >
                        <img
                          loading="lazy"
                          alt="caretRight"
                          src="@/assets/icons/arrowGrey.svg"
                          width="9"
                          height="12"
                        >
                      </ion-col>
                    </ion-row>
                  </a>
                </ion-col>
              </ion-row>
            </ion-col>
          </ion-row>
        </ion-row>
      </ion-col>
      <ion-col
        id="searchbycat_type_truckbeds"
        class="item"
        size="12"
        @click="
          selectCategoryMobile('Truck Beds');
        "
      >
        <ion-row id="type-5">
          <ion-col>
            <ion-row>
              <ion-col
                size="2"
                class="ion-text-center"
              >
                <img
                  loading="lazy"
                  width="60"
                  height="35"
                  class="category-img"
                  alt="trailerImg"
                  src="@/assets/truckBeds.svg"
                >
              </ion-col>
              <ion-col
                size="9"
                style="margin-top: 0px"
                class="ion-text-start title-box"
              >
                <ion-text class="category-title">
                  Truck Beds
                </ion-text>
              </ion-col>
              <ion-col
                size="1"
                class="ion-text-center title-box"
              >
                <span
                  id="caret-5"
                  :class="activeCategory === 'Truck Beds' ? 'rotate-caret' : 'ion-float-right'"
                >
                  <img
                    loading="lazy"
                    alt="caretDown"
                    src="@/assets/icons/arrowDownBlue.svg"
                    width="16"
                    height="16"
                  >
                </span>
              </ion-col>
            </ion-row>
          </ion-col>
          <ion-row
            v-if="activeCategory === 'Truck Beds'"
            :class="activeCategory === 'Truck Beds' ? 'category-show' : 'category-hide'"
          >
            <ion-col
              v-for="item in getTypesMobile('Truck Beds')"
              :id="generatedIdByTypeCategory(item)"
              :key="item.id"
              size="12"
            >
              <ion-row class="sub-box">
                <ion-col
                  size="auto"
                  class="sub-img"
                >
                  <ion-img
                    :src="item.image.image_url"
                    class="menu-image-mobile"
                    :alt="item.name"
                    @ionError="onImageLoadError"
                  />
                </ion-col>
                <ion-col>
                  <a :href="selectedCategoryHref(item.name)">
                    <ion-row
                      class="sub-title-box"
                      @click="goToSelectedCategory(item.name, true, $event)"
                    >
                      <ion-col
                        class="ion-text-start"
                        size="auto"
                      >
                        <ion-text class="sub-title">{{ item.name }}</ion-text> </ion-col><ion-col
                        size="auto"
                        class="arrow-right"
                      >
                        <img
                          loading="lazy"
                          alt="caretRight"
                          src="@/assets/icons/arrowGrey.svg"
                          width="9"
                          height="12"
                        >
                      </ion-col>
                    </ion-row>
                  </a>
                </ion-col>
              </ion-row>
            </ion-col>
          </ion-row>
        </ion-row>
      </ion-col>
    </ion-row>
    <ion-row class="content-container-bottom-mobile">
      <ion-col
        size="12"
        style="margin-bottom: 20px; margin-top: 20px"
        class="ion-text-left ion-justify-content-center d-flex"
      >
        <p class="">
          Explore TrailerTrader’s curated selection of trailer categories, it is designed to simplify your search for trailers,
          horse and livestock trailers, travel trailers and RVs, semi-trailers and truckbeds. We've distilled the industry's extensive
          category list into a user-friendly collection, ensuring you find the right trailer without the technical jargon. 
          Start by selecting a category on our homepage, then fine-tune your choice with detailed filters on the listings page. 
          Don’t forget to set your location so that you have all results near you.
        </p>
      </ion-col>
    </ion-row>
  </ion-grid>
</template>

<script>
import { IonGrid, IonRow, IonCol, IonText, IonButton, IonIcon, IonImg } from "@ionic/vue";
import { chevronForwardOutline } from "ionicons/icons";
import lazyLoad from "../directives/lazyLoad";
import { handleImageError } from "../mixins";
import { pathFromQueryParams } from "../utils/UrlParamUtil";

export default {
  name: "SearchByType",
  components: {
    IonGrid,
    IonRow,
    IonCol,
    IonText,
    IonButton,
    IonIcon,
    IonImg,
  },
  directives: {
    lazyload: lazyLoad,
  },
  mixins: [handleImageError],
  data() {
    return {
      chevronForwardOutline,
      activeCategory: "General Trailers",
      typeList: {},
      activeTypeId: 1,
    };
  },
  computed: {
    menuList() {
      return this.$store.state.megaMenuList;
    },
  },
  watch: {
    menuList(newVal) {
      if (newVal && newVal.length) {
        this.getTypes();
      }
    },
  },
  mounted() {
    this.getTypes();
  },
  methods: {
    focusCategories(ref) {
      this.$refs[ref]?.$el.scrollIntoView(true);
    },
    goToSelectedCategory(categoryName, isRedirect = true, ev) {
      if (ev) {
        ev.preventDefault();
      }
      if (isRedirect) {
        let path = pathFromQueryParams({
          type_id: this.activeTypeId,
          category: categoryName,
          sort: this.$store.getters.sortOption,
        });
        this.$router.push({
          path: `/trailers-for-sale/${path}`,
        });
      }
    },
    selectCategory(type) {
      this.activeCategory = type;
      this.getTypes();
    },
    selectCategoryMobile(type) {
      if(this.activeCategory !== type) {
        this.activeCategory = type;
        this.getTypes();
        document.getElementById("type-" + this.activeTypeId).scrollIntoView();
      } else {
        this.activeCategory = "";
      }
    },
    getTypesMobile(activeCategory) {
      if (this.menuList && this.menuList.length) {
        return this.menuList.find((category) => category.name === activeCategory)?.categories || [];
      }
      return [];
    },
    getTypes() {
      const activeCategory = this.menuList.find((category) => category.name === this.activeCategory);
      this.activeTypeId = activeCategory?.id || 1;
      this.typeList = activeCategory?.categories || [];
    },
    generatedIdByTypeCategory(category) {
      if (this.activeTypeId === 4) {
        return `searchbycat_cat_semi_` + category.name.toLowerCase().split(" ").join("_");
      }
      return `searchbycat_cat_` + category.name.toLowerCase().split(" ").join("_");
    },
    selectedCategoryHref(categoryName) {
      let params = {
        type_id: this.activeTypeId,
        category: categoryName,
        sort: this.$store.getters.sortOption,
      };
      let formattedPath = pathFromQueryParams(params);
      return `${this.domainOrigin}/trailers-for-sale/${formattedPath}`;
    },
  },
};
</script>
