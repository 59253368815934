<template>
  <ion-page>
    <teleport to="head">
      <!-- eslint-disable -->
      <component :is="'script'" type="application/ld+json" v-html="JSON.stringify(richTextSchemaForSearchPrompt)"></component>
    </teleport>
    <ion-content
      id="pdp-content"
      :scroll-events="true"
      :scroll-y="true"
    >
      <mega-menu-overlay />
      <ion-grid class="ion-no-padding">
        <ion-row class="ion-no-margin">
          <ion-col
            size="12"
            class="ion-no-padding"
          >
            <div class="custom-padding-home-page content-container">
              <div class="home-page">
                <img
                  v-if="!isMobile"
                  class="home-page-image-desktop-tablet"
                  src="@/assets/homepage.webp"
                  alt="@/assets/homepage.webp"
                  width="auto"
                  height="463"
                  @load="loaded"
                >
                <img
                  v-else
                  class="home-page-image-mobile"
                  src="@/assets/HomePageMobile.webp"
                  alt="@/assets/HomePageMobile.webp"
                  width="auto"
                  height="365"
                  loading="lazy"
                  @load="loaded"
                >
                <div
                  v-if="pageLoaded"
                  class="home-page-text"
                >
                  <div class="parent-home-page">
                    <h1>Trailertrader Marketplace: new & used trailers for sale near you</h1>
                    <h4>America's choice for buying and selling new or used trailers. Trailertrader marketplace is the go-to source for finding your next trailer or rv.</h4>
                    <SearchBar />
                  </div>
                </div>
                <div :class="!pageLoaded ? 'custom-skeleton-loader' : ''" />
              </div>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
      <browse-by-category />
      <ion-grid
        class="custom-padding-home-page"
      >
        <ion-row>
          <ion-col
            size="12"
          >
            <div
              class="home-page-content-container-bottom"
              id="homePageContentContainerBottom"
            >
              <h2>America’s largest trailer and RV marketplace</h2>
              <div
                v-if="isDesktop"
              >
                <p class="hp-content-text" >
                  TrailerTrader.com is America’s ultimate marketplace for trailers and recreational vehicles where variety meets convenience.
                  From spacious travel trailers to robust utility trailers, we connect buyers and sellers with the perfect match. Whether 
                  you're buying your next cargo trailer or selling your horse trailer, our streamlined platform makes the process effortless. 
                  TrailerTrader.com is your gateway to a vast choice of trailers and RVs near you. Our marketplace is a network of thousands 
                  of local businesses and private sellers located across the USA and Canada and we are confident that you will find the trailer 
                  that fits your needs from an expansive selection located close to your home. 
                  TrailerTrader does not take commissions on sales, you can contact the seller directly for free.
                  Looking to sell your trailer or recreational vehicle, TrailerTrader has thousands of buyers accessing the marketplace every day. 
                  We have a simple selling tool for <a href="/privatesellers">private sellers</a> and a more extensive 
                  inventory management tool for <a href="/dealers">dealers</a>.
                </p>
              </div>
              <div
                v-else
              >
                <p class="hp-content-text">
                  TrailerTrader.com is America’s ultimate marketplace for trailers and recreational vehicles where variety meets convenience.
                  From spacious travel trailers to robust utility trailers, we connect buyers and sellers with the perfect match.
                </p>
                <span 
                    v-if="!seeMoreContent"
                    class="read-more"
                    @click="toggleReadMore"
                  > read more </span>
                <p 
                  v-if="seeMoreContent"
                  class="hp-content-text"
                >
                  Whether you're buying your next cargo trailer or selling your horse trailer, our streamlined platform makes the process effortless. 
                  TrailerTrader.com is your gateway to a vast choice of trailers and RVs near you. Our marketplace is a network of thousands 
                  of local businesses and private sellers located across the USA and Canada and we are confident that you will find the trailer 
                  that fits your needs from an expansive selection located close to your home. 
                  TrailerTrader does not take commissions on sales, you can contact the seller directly for free.
                  Looking to sell your trailer or recreational vehicle, TrailerTrader has thousands of buyers accessing the marketplace every day. 
                  We have a simple selling tool for <a href="/privatesellers">private sellers</a> and a more extensive
                  inventory management tool for <a href="/dealers">dealers</a>.
                 
                </p>
                
                <span 
                    v-if="seeMoreContent"
                    class="read-less"
                    @click="toggleReadMore"
                  > read less </span>
              </div>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
      <Footer v-if="pageLoaded" />
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonContent,
  IonPage,
  IonGrid,
  IonRow,
  IonCol,
} from "@ionic/vue";
import { defineComponent } from "vue";
import SearchBar from "@/components/SearchBar.vue";
import BrowseByCategory from "@/components/BrowseByCategory.vue";
import UserBaseForm from "@/components/user/UserBaseForm.vue";
import { modal } from "@/mixins";

export default defineComponent({
  name: "Home",
  components: {
    IonContent,
    IonPage,
    IonGrid,
    IonRow,
    IonCol,
    BrowseByCategory,
	  SearchBar,
  },
  mixins: [modal],
  data() {
    return {
      pageLoaded: false,
      richTextSchemaForSearchPrompt: {},
      seeMoreContent: false,
    };
  },
  watch: {
    $route (to){
      if (to.name === "Home") {
        setTimeout(this.scrollToTop, 200);
      }
    },
  },
  async mounted() {
    window?.addEventListener("scrollToTop", this.scrollToTop);
    this.setStructuredSchema();

    if (this.$store.state.redirectUrl) {
      this.openModal(UserBaseForm, {
        cssClass: "modal-user-base-form",
        backdropDismiss: true,
      });
    }
  },
  methods: {
    loaded() {
        this.pageLoaded = true;
    },
    scrollToTop() {
      document.querySelector('.home-page-text')?.scrollIntoView();
    },
    setStructuredSchema() {
      this.richTextSchemaForSearchPrompt = {
          "@context": "https://www.schema.org",
          "@type": "WebSite",
          "url": window.location.href,
          "potentialAction": {
            "@type": "SearchAction",
            "target": {
              "@type": "EntryPoint",
              "urlTemplate": `${window.location.origin}/trailers-for-sale?type_id=all&search={search_term_string}`,
            },
            "query-input": "required name=search_term_string",
          },
        };
    },
    toggleReadMore(){
      this.seeMoreContent = !this.seeMoreContent;
      if(!this.seeMoreContent){
        document.getElementById("homePageContentContainerBottom").scrollIntoView({behavior: 'smooth'});
      }
    },
  },
});
</script>